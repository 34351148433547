import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import CurrencyTable from "./CurrencyTable";
import AddCurrency from "./AddCurrency";
import { useState } from "react";
import useGetCurrencyList from "../../../hooks/system/currency/useGetCurrencyList";
import CurrencyFileManagers from "./CurrencyFileManagers";
const Currency = () => {
  const [isOpenAddCurrencyModal, setOpenAddCurrencyModal] = useState(false)
  const { currencyListing, paramsObject, handleSearch, handleSetLimit, handlePageClick } = useGetCurrencyList()

  return (
    <>
      <div className="category px-4">
        <div className="category__head headingBorder__b">
          <h2 className="titleHeading">Currency</h2>
        </div>
        <div className="category_table py-5 space-y-5">
          <div className="flex items-end space-x-4">
            <div className="flex-1">
              <input
                type="text"
                placeholder="Search By Currency Code...."
                className="input"
                onChange={(e) => handleSearch(e.target.value)}
                value={paramsObject.searchQuery}
              />
            </div>
            <div>
              <Button
                onClick={() => setOpenAddCurrencyModal(true)}
                variant="outlined"
                startIcon={<AddIcon />}
                className="primaryBtn-outlined"
              >
                Add Currency
              </Button>
            </div>
            <CurrencyFileManagers/>
            <div>
              <label htmlFor="" className="label">
                Limit
              </label>
              <select name="limit" value={currencyListing.data?.per_page} className='input' onChange={(e) => handleSetLimit(e.target.value)}>
                {Array(5).fill(1).map((_, idx) => {
                  return <option key={idx} value={(idx + 1) * 10}>{(idx + 1) * 10}</option>
                })}
              </select>
            </div>
          </div>
        </div>
        <div>
          <CurrencyTable data={currencyListing} reqObj={paramsObject} onPageNumberClick={handlePageClick} />
        </div>
        {isOpenAddCurrencyModal && <AddCurrency open={isOpenAddCurrencyModal} onClose={() => setOpenAddCurrencyModal(false)} />}
      </div>
    </>
  )
}

export default Currency