import axios from "axios";
import { API_ENDPOINT } from "../config";

export const handleResetPassword = async (data) => {
    const { code, password ,confirm_password} = data
     let fd = new FormData()
    fd.append('code', code)
    fd.append('password', password)
    fd.append('confirm_password', confirm_password)

    let res = await axios({
        method: "POST",
        data: fd,
        url: `${API_ENDPOINT}user_reset_password_check`,
        withCredentials: true,
        // headers: {
        //     'Content-Type': 'application/json'
        // }
    });
    return res;
};
