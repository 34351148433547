import { useRef } from 'react';
import useDownload from '../../../hooks/useDownload';
import { FiUpload, FiX } from "react-icons/fi";
import useUploadfile from '../../../hooks/useUploadfile';

const CurrencyFileManagers = () => {
    const uploadRef = useRef(null);
    const { downloadFile } = useDownload({
        url: 'currency_excel_templete_download', // Your download URL
        onSuccess: () => console.log('File downloaded successfully'),
        onError: (err) => console.error('Error downloading file', err)
    });
    const { setDataToServer } = useUploadfile({
        url: 'currency_excel_upload',
        refreshUrl: 'currency_listing'
        // pass necessary options
    });
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setDataToServer.mutate({ excel_file: file }); // Trigger the mutation with the selected file
    };
    return (
        <div className='flex items-center space-x-5'>
            <div class="flex flex-col justify-center items-center underline-offset-1 underline gap-2">
                <button className="flex gap-2 items-center" onClick={downloadFile}
                >
                    Download
                </button>
            </div>
            <div class="flex flex-col justify-center items-center underline-offset-1 underline gap-2">
                <button
                    className="flex gap-2 items-center "
                    //   onSubmit={handleSubmit(handleFileUpload)}
                    type="submit"
                    onClick={() => uploadRef.current.click()}
                >
                    <FiUpload />
                    Upload
                </button>
                {/* <label for="fileInput">Upload File</label> */}
                <input
                    ref={uploadRef}
                    onChange={handleFileChange}
                    type="file"
                    accept=".xls, .xlsx"
                    id="fileInput"
                    className="hidden"
                />
            </div>
        </div>
    )
}

export default CurrencyFileManagers