import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useCreate from '../useCreate'
import toast from 'react-hot-toast'

const useDeleteProduct = () => {
    const { categoryId } = useParams()
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { setDataToServer } = useCreate({
        url: 'product_delete',
        refreshUrl: 'product_listing',
        onSuccess: () => {
            // handleResetForm()
            toast.success("Product Deleted Successfully", {
                position: "top-right",
                duration: 3000
            })
            setTimeout(() => {
                window.location.reload()
            },2000)
        }
    })
    const handleSubmit = (data) => {
        setDataToServer.mutate(data)
    }

    return { handleSubmit,setDataToServer }
}

export default useDeleteProduct