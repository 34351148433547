import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Autocomplete from '@mui/material/Autocomplete';
import useRead from '../../../../hooks/useRead';
import TextField from '@mui/material/TextField';
import useCreate from '../../../../hooks/useCreate';
import toast from 'react-hot-toast';


const initialAttributeData = {
    pageNo: 0,
    limit: 10,
    searchQuery: "",
};

const schema = yup
    .object({
        stock: yup.string().matches(/^[0-9]+$/, 'Stock must contain only numbers').required('Stock is required.'),
        price: yup.string().matches(/^[0-9]+$/, 'Price must contain only numbers').required('Price is required.'),
        offer_price: yup.string().matches(/^[0-9]+$/, 'Offer Price must contain only numbers'),
        weight: yup.string().matches(/^[0-9]+$/, 'Weight must contain only numbers'),
        weight_unit: yup.string(),
        no_of_pieces: yup.string().matches(/^[0-9]+$/, 'Number of pieces must contain only numbers'),
        category_id: yup.string(),
        product_id: yup.string(),
        dimension_attribute_key: yup.string(),
        dimension_attribute_value: yup.string(),
    })
    .required()

const AddSubProduct = () => {
    const { parentId, categoryId } = useParams()
    const { control, register, handleSubmit, setValue, getValues, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            attributes: [],
            attributesName: [],
            category_id: categoryId,
            product_id: parentId,
            weight_unit: 'kg'
        },
    })
    const { fields, append, remove } = useFieldArray({
        control,
        name: "attributes",
    });
    const { append: appendAttributeName, remove: removeAttributeFieldsName } = useFieldArray({
        control,
        name: "attributesName",
    });
    const { data: attributeList } = useRead({
        initialData: initialAttributeData,
        url: "attribute_listing",
        method: "GET",
    });

    const { setDataToServer: createProduct } = useCreate({
        url: 'sub_product_create',
        refreshUrl: 'fetch_sub_product_list',
        onSuccess: (res) => {
            toast.success("Product Added Successfully", {
                position: "top-right",
                duration: 3000
            })
            navigate(-1)
        }
    })
    const navigate = useNavigate();

    const handleProductSubmit = (data) => {
        createProduct.mutate(data)
    }

    const handleAddAttribute = () => {
        append({ id: "", name: "", value: "" })
        appendAttributeName({ name: "" })
    }

    const handleRemoveAttribute = (index) => {
        remove(index)
        removeAttributeFieldsName(index)
    }

    return (
        <>
            <div className='container_xxl bg-[#eceff1] px-4'>
                <div className=' fixed w-full bg-white left-0 top-0 z-10'>
                    <div className='flex items-center space-x-4 headingBorder__b pl-[100px]'>
                        <div>
                            <IconButton onClick={() => navigate(-1)}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div>
                            <h1 className='heading'>Add Sub Product</h1>
                        </div>
                    </div>
                </div>
                <div className='form-body py-8 mt-20'>
                    <form className=' space-y-5' autoComplete='off' onSubmit={handleSubmit(handleProductSubmit)}>
                        <div className='grid grid-cols-1 gap-4'>
                            <div className='mb-3 flex items-center justify-between border-b border-gray-400 '>
                                <h1 className='font-semibold text-2xl pb-3'>Product Info</h1>
                            </div>
                            <div className=' grid grid-cols-3 gap-5'>
                                <div>
                                    <label htmlFor="Product Manufacturer" className='label'>Stock </label>
                                    <input {...register('stock')} className='input' placeholder='Stock' />
                                    <small className=' text-red-600'>{errors.stock?.message}</small>
                                </div>
                                <div>
                                    <label htmlFor="Image Name" className='label'>Price </label>
                                    <input {...register('price')} className='input' placeholder='Price' />
                                    <small className=' text-red-600'>{errors.price?.message}</small>
                                </div>
                                <div>
                                    <label htmlFor="Standard" className='label'>Offer price </label>
                                    <input {...register('offer_price')} className='input' placeholder='Office Price' />
                                    <small className=' text-red-600'>{errors.offer_price?.message}</small>
                                </div>
                                <div>
                                    <label htmlFor="Standard" className='label'>Weight </label>
                                    <input {...register('weight')} className='input' placeholder='Weight' />
                                    <small className=' text-red-600'>{errors.weight?.message}</small>
                                </div>
                                <div>
                                    <label htmlFor="Standard" className='label'>Weight Unit </label>
                                    <select {...register('weight_unit')} className='input'>
                                        <option value="kg">kg</option>
                                        <option value="g">grams</option>
                                    </select>
                                    <small className=' text-red-600'>{errors.weight_unit?.message}</small>
                                </div>
                                <div>
                                    <label htmlFor="Standard" className='label'>No of pieces </label>
                                    <input {...register('no_of_pieces')} className='input' placeholder='No of pieces' />
                                    <small className=' text-red-600'>{errors.no_of_pieces?.message}</small>
                                </div>
                                <div>
                                    <label htmlFor="Standard" className='label'>Dimension attribute key </label>
                                    <small className=' text-gray-600'>Enter each key pipe separated eg: D|A|B</small>
                                    <input {...register('dimension_attribute_key')} className='input' placeholder='Enter dimension attribute key eg: D|A|B' />
                                    <small className=' text-red-600'>{errors.dimension_attribute_key?.message}</small>
                                </div>
                                <div>
                                    <label htmlFor="Standard" className='label'>Dimension attribute value </label>
                                    <small className=' text-gray-600'>Enter each value pipe separated eg: 10.9|10.2|9.0</small>
                                    <input {...register('dimension_attribute_value')} className='input' placeholder='Enter dimension attribute value eg: 10.9|10.2|9.0' />
                                    <small className=' text-red-600'>{errors.dimension_attribute_value?.message}</small>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-1 gap-4'>
                            <div className='mb-3 flex items-center justify-between border-b border-gray-400 '>
                                <h1 className='font-semibold text-2xl pb-3'>Product Attributes</h1>

                                <button onClick={handleAddAttribute} type='button' className=' text-blue-600 font-semibold'>Add Attributes</button>
                            </div>
                            <div>
                                <div className=' divide-y'>
                                    {fields?.map((field, index) => {
                                        return <div key={field.id} className='grid grid-cols-3 gap-4 py-4 items-center'>
                                            <div>
                                                <label className='label'>Attribute Name</label>
                                                <Controller
                                                    name={`attributes.${index}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            fullWidth
                                                            disablePortal
                                                            inputValue={getValues(`attributesName.${index}.name`)}
                                                            onInputChange={(e, value) => {
                                                                setValue(`attributesName.${index}.name`, value)
                                                            }}
                                                            onChange={(event, newValue) => {
                                                                field.onChange(newValue);
                                                            }}
                                                            getOptionLabel={(option) => option.name}
                                                            id="combo-box-demo"
                                                            className="bg-white"
                                                            options={attributeList?.data?.data || []}
                                                            renderInput={(params) => <TextField v placeholder="Search attribute" {...params} />}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="Standard" className='label'>Attribute Value </label>
                                                <input {...register(`attributes.${index}.value`)} className='input' placeholder='Attribute Value' />
                                                <small className=' text-red-600'>{errors.weight_unit?.message}</small>
                                            </div>
                                            <button onClick={() => handleRemoveAttribute(index)} className=' text-left text-red-600 font-semibold'>
                                                Remove
                                            </button>
                                        </div>
                                    })}

                                </div>
                            </div>
                        </div>


                        <div className='text-center my-6'>
                            {<Button type='submit' variant="contained" className='primaryBtn-contained'>
                                Save Product
                            </Button>}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddSubProduct