import Layout from "../../../components/layout";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../../../components/spinner";
import Errors from "../../errors";
import { getCustomerList } from "../../../apis/customers";
import { useState } from "react";
import { useSearchParams } from 'react-router-dom';
import Pagination from "../../../components/elements/pagination";

function Customers() {
    const [searchParams, setSearchParams] = useSearchParams(); 
    
    const initialState = {
        limit: 10,
        pageNo: 0,
        searchQuery:''
    };
    const [customerState, setCustomerState] = useState(initialState);

    const [currentPage, setCurrentPage] = useState(0);

    const [dataCount, setDataCount] = useState(0);
    const limit = 10;
    const [data, setData] = useState([]);
    const handleGetCustomerList = useQuery(
        ["customerlist", customerState],
        () => getCustomerList(customerState), // Pass customerState as queryParams
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            onSuccess: (response) => {
                const { total_count } = response.data;
                setDataCount(Math.ceil(total_count / customerState.limit));
            },
        }
    );

    const totalCount = handleGetCustomerList.data?.data?.total_count;

    const handleSearchVal = (e) => {
        const { name, value } = e.target;
        const newData = {
            ...customerState,
            pageNo: 0,
            [name]: value,
        };
        setCustomerState(newData);
        
        // Update search parameters in the URL
        setSearchParams(new URLSearchParams(newData));
    };
    
    const handlePageClick = (val) => {
        let data = {
            ...customerState,
            pageNo: val.selected,
        };
        setCustomerState(data);
    };

  return (
    <>
      <div className="category  px-5">
        <div className="category__head headingBorder__b">
          <h2 className="titleHeading">Customers</h2>
        </div>
        <div className="category_table py-5 space-y-5">
          <div className="flex items-center space-x-4">
            <div className="flex-1">
              <input
                name="searchQuery"
                value={customerState.searchQuery}
                onChange={handleSearchVal}
                type="text"
                placeholder="Search Customer...."
                className="input "
              />
            </div>
          </div>

          {handleGetCustomerList.isLoading ? (
            <Spinner />
          ) : handleGetCustomerList.status === "error" ? (
            <Errors errorObject={handleGetCustomerList.error} inline />
          ) : (
            <>
              <div className="overflow-x-auto bg-white rounded-lg shadow relative ">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                  <thead>
                    <tr className="text-left">
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                        Sr.no
                      </th>
                      <th className="bg-gray-100 sticky top-0 text-center border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                        Email Id
                      </th>
                      {/* <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        First Name
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Last Name
                      </th> */}
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Name
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Mobile number
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Address{" "}
                      </th>
                      {/* <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Date of Birth
                      </th> */}
                      {/* <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Gender
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {handleGetCustomerList?.data?.data?.map(
                      (customerData, idx) => {
                        const {
                          id,
                          email,
                          first_name,
                          last_name,
                          name,
                          phone,
                          date_of_birth,
                          gender,
                          address,
                        } = customerData;
                        return (
                          <tr key={id}>
                            <td className="border-dashed border-t border-gray-200 userId">
                              <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                {customerState.limit *
                                  (customerState.pageNo + 1) -
                                  (customerState.limit - (idx + 1))}
                              </span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 emailAddress">
                              <span className="text-gray-700 px-6 py-3 text-center block text-sm">
                                {email || "---"}
                              </span>
                            </td>
                            {/* <td className="border-dashed border-t border-gray-200 firstName">
                              <span className="text-gray-700 px-6 py-3 text-center block text-sm">
                                {first_name || "---"}
                              </span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 lastName">
                              <span className="text-gray-700 px-6 py-3 text-center block text-sm">
                                {last_name || "---"}
                              </span>
                            </td> */}
                            <td className="border-dashed border-t border-gray-200 lastName">
                              <span className="text-gray-700 px-6 py-3 text-center block text-sm">
                                {name || "---"}
                              </span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 lastName">
                              <span className="text-gray-700 px-6 py-3 text-center block text-sm">
                                {phone || "---"}
                              </span>
                            </td>
                            {/* <td className="border-dashed border-t border-gray-200 lastName">
                              <span className="text-gray-700 px-6 py-3 text-center block text-sm">
                                {date_of_birth || "---"}
                              </span>
                            </td> */}
                            {/* <td className="border-dashed border-t border-gray-200 lastName">
                              <span className="text-gray-700 px-6 py-3 text-center block text-sm">
                                {gender || "---"}
                              </span>
                            </td> */}
                            <td className="border-dashed border-t border-gray-200 lastName">
                              <span className="text-gray-700 px-6 py-3 text-center block text-sm">
                                {address[0]?.address}
                              </span>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
              <div className="my-7">
                <Pagination
                  currentPage={currentPage}
                  lengthofItems={totalCount}
                  limit={limit}
                  onPageChange={handlePageClick}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Customers;
