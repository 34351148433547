import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useParams, useNavigate } from 'react-router-dom';
import Editor from '../../../components/elements/Editor';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import DatePicker from 'react-datepicker';
import Autocomplete from '@mui/material/Autocomplete';
import useRead from '../../../hooks/useRead';
import TextField from '@mui/material/TextField';
import useCreate from '../../../hooks/useCreate';
import toast from 'react-hot-toast';
import SubProductListing from './SubProduct/SubProductListing';
import useFetchSingleProduct from '../../../hooks/products/useFetchSingleProduct';
import { useState } from 'react';

const initialCateData = {
    pageNo: 0,
    limit: 10,
    searchQuery: "",
};

const schema = yup
    .object({
        name: yup.string().required('Product Name is required.'),
        slug: yup.string().required('Slug is required.').matches(/^[A-Za-z0-9-]+$/, 'Invalid Slug. Only letters, numbers, and hyphens are allowed.'),
        description: yup.string(),
        model: yup.string(),
        category_id: yup.string(),
        manufacturer: yup.string(),
        image_name: yup.string(),
        additional_image_name: yup.string(),
        standard: yup.string(),
        date_available: yup.date(),
        product_code: yup.string().required('Product code is required.'),
        quantity: yup.string().required('Quantity is required.'),
        status: yup.string(),
        standard_desc: yup.string(),
        seo: yup.object().shape({
            seo_keyword: yup.string(),
            meta_keyword: yup.string(),
            meta_title: yup.string(),
            meta_description: yup.string()
        })
    })
    .required()
function EditProduct({ }) {
    const { id } = useParams()
    const [categoryId, setCategoryId] = useState('')
    const [categoryName, setCategoryName] = useState('')
    const { control, register, handleSubmit, setValue, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
    })
    const { data: categoryList, paramsObject, setGetListParams } = useRead({
        initialData: initialCateData,
        url: "category_listing",
        method: "GET",
    });
    const singleProduct = useFetchSingleProduct({
        product_id: id,
        onSuccess: (res) => {
            let singleData = res?.data
            setCategoryId(singleData?.category_id)
            setCategoryName(singleData?.category_name)
            Object.keys(singleData).forEach((key) => {
                setValue(key, singleData[key])
            })
        }
    })

    const { setDataToServer: createProduct } = useCreate({
        url: 'product_create',
        onSuccess: (res) => {
            console.log(res.data);
            toast.success("Product Added Successfully", {
                position: "top-right",
                duration: 3000
            })
        }
    })
    const navigate = useNavigate();

    const handleProductSubmit = (data) => {
        createProduct.mutate(data)
    }

    return (
        <>
            <div className='container_xxl bg-[#eceff1] px-4'>
                <div className=' fixed w-full bg-white left-0 top-0 z-10'>
                    <div className='flex items-center space-x-4 headingBorder__b pl-[100px]'>
                        <div>
                            <IconButton onClick={() => navigate(-1)}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div>
                            <h1 className='heading'>Edit Product</h1>
                        </div>
                    </div>
                </div>
                <div className='form-body py-8 mt-20'>
                    <form className='' autoComplete='off' onSubmit={handleSubmit(handleProductSubmit)}>
                        <div className='grid grid-cols-1 gap-4'>
                            <div className='mb-3 flex items-center justify-between border-b border-gray-400 '>
                                <h1 className='font-semibold text-2xl pb-3'>Product Info</h1>
                            </div>
                            <div>
                                <label htmlFor="Product Name" className='label'>Product Name <small className="text-red-700">*</small></label>
                                <input {...register('name')} className='input' placeholder='Product Name' />
                                <small className=' text-red-600'>{errors.name?.message}</small>
                            </div>
                            <div>
                                <label htmlFor="Slug" className='label'>Slug <small className="text-red-700">*</small></label>
                                <input {...register('slug',{
                                    required: "Slug is required",
                                    pattern: {
                                      value: /^[A-Za-z0-9-]+$/,
                                      message: 'Invalid Slug. Only letters, numbers, and hyphens are allowed.',
                                    },
                                })} placeholder='Slug' className='input' />
                                <small className=' text-red-600 block'>{errors.slug?.message}</small>
                            </div>
                            <div>
                                <label htmlFor="Description" className='label'>Description</label>
                                <Editor control={control} name={'description'} />
                            </div>

                            <div className='grid grid-cols-2 gap-4'>
                                <div>
                                    <label htmlFor="Product Model" className='label'>Product Model </label>
                                    <input {...register('model')} className='input' placeholder='Product Model' />
                                    <small className=' text-red-600'>{errors.model?.message}</small>
                                </div>
                                <div>
                                    <label htmlFor="Product Code" className='label'>Product Code </label>
                                    <input {...register('product_code')} className='input' placeholder='Product Code' />
                                    <small className=' text-red-600'>{errors.product_code?.message}</small>
                                </div>
                                <div>
                                    <label htmlFor="Quantity" className='label'>Quantity </label>
                                    <input {...register('quantity')} className='input' placeholder='Quantity' />
                                    <small className=' text-red-600'>{errors.quantity?.message}</small>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="Product Manufacturer" className='label'>Category </label>
                                <Controller
                                    name="category_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Autocomplete
                                            fullWidth
                                            freeSolo
                                            disablePortal
                                            value={field.value}
                                            inputValue={categoryName}
                                            onChange={(event, newValue) => {
                                                field.onChange(newValue ? newValue.id : '');
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                setCategoryName(newInputValue);
                                            }}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => option?.id === value}
                                            id="combo-box-demo"
                                            className="bg-white"
                                            options={categoryList?.data?.data || []}
                                            renderInput={(params) => <TextField placeholder="Search parent" {...params} />}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <label htmlFor="Product Manufacturer" className='label'>Product Manufacturer </label>
                                <input {...register('manufacturer')} className='input' placeholder='Product Manufacturer' />
                                <small className=' text-red-600'>{errors.manufacturer?.message}</small>
                            </div>
                            <div className=' grid grid-cols-2 gap-5'>
                                <div>
                                    <label htmlFor="Product Manufacturer" className='label'>Product Image </label>
                                    <div className='w-52 h-52 mt-2'>
                                        <img src={singleProduct?.data?.data?.image_name} alt="product image" className='w-full h-full'/>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="Product Manufacturer" className='label'>Additional Product Image </label>
                                    <div className='w-52 h-52 mt-2'>
                                        <img src={singleProduct?.data?.data?.additional_image_name} alt="product image" className='w-full h-full'/>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="Standard" className='label'>Standard </label>
                                <input {...register('standard')} className='input' placeholder='Standard' />
                                <small className=' text-red-600'>{errors.standard?.message}</small>
                            </div>
                            <div>
                                <label htmlFor="Standard Description" className='label'>Standard Description</label>
                                <Editor control={control} name={'standard_desc'} />
                            </div>

                            <div className='grid grid-cols-2 gap-4'>
                                <div className='w-full'>
                                    <label htmlFor="Date Available" className='label'>Date Available</label>
                                    <Controller
                                        name="date_available"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                className="input"
                                                showIcon
                                                selected={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="Select a Date"
                                            />
                                        )}
                                    />
                                </div>
                                <div>
                                    <label className='label'>Status</label>
                                    <select {...register('status')} name="status" className='input'>
                                        <option value="">--Select Option--</option>
                                        <option value="available">Available</option>
                                        <option value="out_of_stock">Out of Stock</option>
                                        <option value="not_available">Not Available</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>Search Engine Optimization</h1>
                            </div>
                            <div className='grid grid-cols-2 gap-4'>
                                <div className='mb-2'>
                                    <label className='label'>Meta Title</label>
                                    <input {...register('seo.meta_title')} type="text" placeholder='Meta Title' name="seo.meta_title" className='input' />
                                </div>
                                <div className='mb-2'>
                                    <label className='label'>Meta Keywords</label>
                                    <input {...register('seo.meta_keyword')} type="text" placeholder='Meta Keywords' name="seo.meta_keyword" className='input' />
                                </div>
                                <div className='mb-2'>
                                    <label className='label'>Meta Description</label>
                                    <input {...register('seo.meta_description')} type="text" placeholder='Meta Description' name="seo.meta_description" className='input' />
                                </div>
                                <div className='mb-2'>
                                    <label className='label'>SEO Keyword</label>
                                    <input {...register('seo.seo_keyword')} type="text" placeholder='SEO Keyword' name="seo.seo_keyword" className='input' />
                                </div>
                            </div>
                        </div>
                        <SubProductListing parentId={id} categoryId={categoryId} />
                        <div className='text-center my-6'>
                            {<Button type='submit' variant="contained" className='primaryBtn-contained'>
                                Save Product
                            </Button>}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default EditProduct