import useRead from "../../useRead";

const useFetchSingleShippingData = ({id}) => {
    let initialData = {
        id
    }
    // currency_listing
    const { data: singleShipmentData } = useRead({
        initialData: initialData,
        url: "fetch_single_shipping_method",
        method: "GET",
        queryName: 'singleShippingMethod',
        onSuccess: (res) => {
            // onSuccess(res)
        }
    });
    return singleShipmentData
}

export default useFetchSingleShippingData