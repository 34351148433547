import ShipmentForm from '../../../components/system/shipment/ShipmentForm'

const CreateShipmentData = () => {
    return (
        <div>
            <div className="category px-4">
                <div className="category__head headingBorder__b">
                    <h2 className="titleHeading">Create New Shipment</h2>
                </div>
            </div>
            <div>
                <ShipmentForm/> 
            </div>
        </div>
    )
}

export default CreateShipmentData