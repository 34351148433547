import useRead from "../../useRead";


let initialData = {
    pageNo: 0,
    limit: 100,
    searchQuery: '',
}

const useGetCountryCodeList = () => {
 // currency_listing
 const { data: countryCodeList, paramsObject, setGetListParams } = useRead({
    initialData: initialData,
    url: "country_listing",
    method: "GET",
    queryName: 'countryCodeList',
    onSuccess: (res) => {
        // onSuccess(res)
    }
});
const handleSearch = (value) => {
    setGetListParams(prev => {
        return {
            ...prev,
            searchQuery: value
        }
    })
}
const handleSetLimit = (value) => {
    setGetListParams(prev => {
        return {
            ...prev,
            limit: value
        }
    })
}
const handlePageClick = (val) => {
    let pageNo = {
        ...paramsObject,
        pageNo: val.selected,
    };
    setGetListParams(pageNo);
    // setSearchParams(pageNo);
};
return { countryCodeList, paramsObject, handleSearch, handleSetLimit, handlePageClick }
}

export default useGetCountryCodeList