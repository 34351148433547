import { useEffect, useState } from 'react'
import spinner from '../../assets/images/common/spinner.gif'
import logo from "../../assets/images/common/logo.png"
import { useMutation } from "@tanstack/react-query";
import Cookies from 'universal-cookie';
import { PROJECT_NAME, TOKEN_KEY } from '../../config';
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { toast } from 'react-hot-toast';
import { handleResetPassword } from '../../apis/resetpassword'; // Import the correct API function

const cookies = new Cookies();
const ResetPassword = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/dashboard";

    let token = sessionStorage.getItem(TOKEN_KEY);
    const initialData = {
        code: '',
        password: '',
        confirm_password: ''
    }
    const [userData, setUserData] = useState(initialData);
    const [error, setError] = useState('');
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        setUserData((prevData) => ({ ...prevData, code }));
    }, [location]);

    const handleChangeInput = (e) => {
        let value = e.target.value;
        let data = {
            ...userData,
            [e.target.name]: value
        }
        setUserData(data);
    }

    const { mutate, isLoading } = useMutation((data) => handleResetPassword(data), {
        onSuccess: (response) => {
            if (response.status === 200) {
                toast.success("Password reset successfully!", {
                    position: "top-right",
                    duration: 3000,
                });
                navigate("/login");
            }
        },
        onError: (err) => {
            if (err.response.status === 400) {
                setError(err.response.data.message);
                toast.error("Oops! Something went wrong.", {
                    position: "top-right",
                    duration: 3000,
                });
                return;
            }
        }
    });

    const handleSubmitResetPasswordForm = (e) => {
        e.preventDefault();
        if (userData.password !== userData.confirm_password) {
            setError("Passwords do not match");
            return;
        }
        mutate(userData);
    }

    if (token) return <Navigate to="/dashboard" replace />

    return (
        <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-gray-100">
            <div className="flex flex-col items-center justify-center w-full flex-1 px-20 ">
                <div className="bg-white shadow-2xl flex w-2/3 max-w-4xl">
                    <div className="w-3/5 py-20 px-9">
                        <p className="text-3xl font-semibold mb-4 roboto">Reset Password</p>
                        <form className="space-y-4" autoComplete='on' onSubmit={handleSubmitResetPasswordForm}>
                            <div>
                                <label htmlFor="password" className="block text-xl roboto">New Password</label>
                                <input 
                                    type="password" 
                                    required
                                    className="border-solid border-2 border-gray-400 py-2 px-2 rounded w-full mt-2 placeholder:italic placeholder:text-sm"
                                    name="password" 
                                    placeholder="Enter your new password" 
                                    value={userData.password} 
                                    onChange={handleChangeInput} 
                                />
                            </div>
                            <div>
                                <label htmlFor="confirmPassword" className="block text-xl roboto">Confirm Password</label>
                                <input 
                                    type="password" 
                                    required
                                    className="border-solid border-2 border-gray-400 py-2 px-2 rounded w-full mt-2 placeholder:italic placeholder:text-sm"
                                    name="confirm_password" 
                                    placeholder="Confirm your new password" 
                                    value={userData.confirm_password} 
                                    onChange={handleChangeInput}
                                />
                            </div>
                            {error && <div className='text-red-700 text-center'><small>{error}</small></div>}
                            {isLoading ? (
                                <div className='w-8 h-8 mx-auto'>
                                    <img src={spinner} alt="spinner" className='w-full h-full' />
                                </div>
                            ) : (
                                <div className="button">
                                    <button className="w-full py-2 bg-[color:var(--color1)] text-white rounded roboto font-semibold text-2xl">
                                        Reset Password
                                    </button>
                                </div>
                            )}
                        </form>
                    </div>
                    <div className="w-2/5 px-12 text-center flex flex-col justify-center items-center bg-primary">
                        <p className="roboto font-semibold text-white">
                            Welcome To <span className="font-extrabold">{PROJECT_NAME}</span>
                        </p>
                        <div className="mt-8 w-60 h-40 relative left-2 mx-0">
                            <img src={logo} alt="logo" className='w-full h-full object-contain' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;
