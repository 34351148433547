import { Link, Outlet, useLocation } from "react-router-dom"
const ShipmentLayout = () => {
    const {pathname} = useLocation()
  return (
    <div>
       <div className="category px-4">
       <div className="category__head headingBorder__b">
          <h2 className="titleHeading">Shippments</h2>
        </div>
            <div className=" my-5">
                <ul className=" flex items-center border-b pb-4 space-x-4">
                    <li>
                        <Link to={`/system/shipping`} className={`px-6 py-2 inline-block rounded font-semibold text-sm hover:bg-yellow-300 hover:text-black  ${pathname === '/system/shipping' ? 'bg-yellow-300 text-black' : 'text-gray-500'}`}>By Sea</Link>
                    </li>
                    <li>
                        <Link to={`/system/shipping-by-air`} className={`px-6 py-2 inline-block rounded font-semibold text-sm hover:bg-yellow-300 hover:text-black  ${pathname === '/system/shipping-by-air' ? 'bg-yellow-300 text-black' : 'text-gray-500'}`}>By Air</Link>
                    </li>
                </ul>
            </div>
            <Outlet />
        </div>
    </div>
  )
}

export default ShipmentLayout