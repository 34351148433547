import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import Spinner from "../../../components/spinner";
import Errors from "../../errors";
import Pagination from "../../../components/elements/pagination";
import useGetCategoryList from "../../../hooks/category/useGetCategoryList";
import { RiDeleteBinLine } from "react-icons/ri";
import React, { useRef, useState } from "react";
import DeleteModal from "../../../components/elements/DeleteModal";
import useDeleteCategory from "../../../hooks/category/useDeleteCategory";
import useDownload from "../../../hooks/useDownload";
import toast from "react-hot-toast";
const Category = () => {
  const {
    data,
    handlePageClick,
    handleSearchVal,
    paramsObject,
    handleSetLimit,
  } = useGetCategoryList();
  const [category, setCategory] = useState(data.data?.data || []);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDelete = (id) => {
    setCategory(id);
    setDeleteModalOpen(true);
  };
  const { downloadFile: downloadFileHook } = useDownload({
    url: "product_excel_templete_download", // Your download URL
    onSuccess: () => toast.success("File downloaded successfully"),
    onError: (err) => toast.error("No product exist for this category"),
  });

  const handleDownload = (id) => {
    downloadFileHook(id);
  };
  
  const { handleSubmit, setDataToServer } = useDeleteCategory();
  return (
    <>
      <div className="category px-4">
        <div className="category__head headingBorder__b">
          <h2 className="titleHeading">Category</h2>
        </div>
        <div className="category_table py-5 space-y-5">
          <div className="flex items-end space-x-4">
            <div className="flex-1">
              <input
                type="text"
                placeholder="Search By Key Word...."
                className="input"
                onChange={handleSearchVal}
                value={paramsObject.searchQuery}
              />
            </div>
            <div>
              <Link to={`add-category`} className="block">
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  className="primaryBtn-outlined"
                >
                  Add Category
                </Button>
              </Link>
            </div>
            <div>
              <label htmlFor="" className="label">
                Limit
              </label>
              <select
                name="limit"
                value={data.data?.per_page}
                className="input"
                onChange={handleSetLimit}
              >
                {Array(5)
                  .fill(1)
                  .map((_, idx) => {
                    return (
                      <option key={idx} value={(idx + 1) * 10}>
                        {(idx + 1) * 10}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          {data.isLoading || data.isFetching ? (
            <Spinner />
          ) : data.status === "error" ? (
            <Errors errorObject={data.error} inline />
          ) : (
            <>
              <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                  <thead>
                    <tr className="text-left">
                      <th className="bg-gray-100 w-[900px] sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-left">
                        Category Name
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                        Category Code
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                        Download
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.data?.data?.map((productData, idx) => {
                      const { id, category_code, name } = productData || {};
                      return (
                        <tr key={idx}>
                          <td className="border-dashed border-t border-gray-200 firstName">
                            <div className=" p-2 border-r text-xs">{name}</div>
                          </td>
                          <td className="border-dashed border-t border-gray-200 firstName border-r">
                            <span className="text-gray-700 px-6 py-3 flex items-center text-xs">
                              {category_code}
                            </span>
                          </td>
                          <td className="border-dashed border-t text-center justify-center items-center border-gray-200 firstName border-r">
                            <p
                              className="flex-1 text-blue-500 underline text-sm cursor-pointer text-center items-center"
                              onClick={() => handleDownload(id)}
                            >
                              Download product
                            </p>
                          </td>
                          <td className="border-dashed border-t border-gray-200 emailAddress">
                            <div className="flex items-center justify-center">
                              <Link to={`edit-category/${id}?mode=edit`}>
                                <IconButton>
                                  <EditIcon />
                                </IconButton>
                              </Link>
                              <RiDeleteBinLine
                                onClick={() => handleDelete(id)}
                                className="text-lg cursor-pointer text-gray-600"
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div>
                <Pagination
                  currentPage={+paramsObject.pageNo}
                  lengthofItems={data.data?.total_count}
                  limit={paramsObject.limit}
                  onPageChange={handlePageClick}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <DeleteModal
        open={isDeleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title={"Delete Category"}
        message={"Are you sure you want to delete this category?"}
        onDelete={() => handleSubmit({ id: category })}
      />
    </>
  );
};

export default Category;
