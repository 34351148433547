import logo from './assets/images/common/logo.png'
export const PROJECT_NAME = 'Online Fittings'
export const PROJECT_META_NAME = 'onlineFitting'
export const ENVIRONMENT = 'production' //production | development
export const TOKEN_KEY = ENVIRONMENT === 'developement' ? `_${PROJECT_META_NAME}_token` : `_${PROJECT_META_NAME}_token_dev`
export const SITE_KEY = ENVIRONMENT === 'developement' ? '6Ld0BLkmAAAAAECagQW1dA8yMo5gOUDq51lB7aS5' : '6Ld0BLkmAAAAAECagQW1dA8yMo5gOUDq51lB7aS5'

export const API_ENDPOINT = ENVIRONMENT === 'production' ? `https://api.onlinefittings.com/` : 'https://stage-api.onlinefittings.com/' 
{/*tpsapi.renderly.in */}
export const IDLE_TIME_IN_SECONDS = 300

export const ENCRYPT_AND_DECRYPT_KEY = 'wjEXA38AgXM1FWTxojcsBqykcV9NpX3h'
export const DATA_VECTOR = 'dGEBTb1YQszEhk5G' 

export const LOGO = logo

export const SLUG_SPECIAL_CHAR = /[^\w-]/g