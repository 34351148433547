import { Link } from "react-router-dom";
import useRead from "../../../../hooks/useRead";
import Spinner from "../../../../components/spinner";
import Errors from "../../../errors";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

const SubProductListing = ({ parentId, categoryId }) => {
  const { data: subProductList } = useRead({
    initialData: {
      product_id: parentId,
    },
    url: "fetch_sub_product_list",
    method: "GET",
  });
  return (
    <div>
      <div className="my-7 flex items-center justify-between border-b border-gray-400">
        <h1 className="font-semibold text-2xl pb-3">Sub Products</h1>
        <Link
          to={`/catalog/products/add-sub-product/${parentId}/${categoryId}`}
          className=" text-blue-600 font-semibold"
        >
          Add Sub Product
        </Link>
      </div>
      <div>
        {subProductList.isLoading || subProductList.isFetching ? (
          <Spinner />
        ) : subProductList.status === "error" ? (
          <Errors errorObject={subProductList.error} inline />
        ) : (
          <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
              <thead>
                <tr className="text-left">
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Stock
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    price
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    weight
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    SKU
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold text-center tracking-wider uppercase text-xs">
                    dimension attribute key
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold text-center tracking-wider uppercase text-xs">
                    dimension attribute value
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold  tracking-wider uppercase text-xs">
                    Action
                  </th>
                </tr>
              </thead>
              {subProductList.data?.data?.length > 0
              ? <tbody>
              {subProductList.data?.data?.map((productData, idx) => {
                const {
                  stock,
                  price,
                  weight,
                  weight_unit,
                  sku_code,
                  dimension_attribute_key,
                  dimension_attribute_value,
                } = productData;
                return (
                  <tr key={idx}>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                        {stock}
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                        {price}
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                        {weight} {weight_unit}
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                        {sku_code}
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <p className="text-gray-700 px-6 py-3 text-sm text-center">
                        {dimension_attribute_key}
                      </p>
                    </td>
                    <td className="border-dashed border-t border-gray-200 emailAddress">
                      <div className="flex items-center justify-center text-center">
                        {dimension_attribute_value}
                      </div>
                    </td>
                    <td className="border-dashed border-t border-gray-200 emailAddress">
                      <div className="flex items-center justify-center">
                        <Link to={`/catalog/products/edit-sub-product/${parentId}/${categoryId}`}>
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody> 
              : <tr>
                <td colSpan={7}>
                    <p className=" text-center font-semibold text-gray-500 py-3">No Product Found</p>
                </td>
              </tr> }
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubProductListing;
