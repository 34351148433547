import { useEffect, useState } from 'react'
import SwitchToggle from '../../../components/elements/switchtoggle/SwitchToggle'
import useCreate from '../../../hooks/useCreate'
import toast from 'react-hot-toast'

const CategoryStatusUpdate = ({ status,categoryId }) => {
    const [categoryStatus, setCategoryStatus] = useState(status)
    useEffect(() => {
        setCategoryStatus(status)
    }, [status])
    const { setDataToServer } = useCreate({
        url: 'category_status_update',
        refreshUrl: 'category_listing',
        onSuccess: () => {
            toast.success("Category Status Updated Successfully", {
                position: "top-right",
                duration: 3000
            })
        }
    })
    const handleChangeChecked = (e) => {
        let checked = e.target.checked
        setDataToServer.mutate({
            id:categoryId,
            status: checked
        })
        setCategoryStatus(checked)
    }
    return (
        <div className="py-5 grid grid-cols-2">
            <div className=" -ml-4">
                <SwitchToggle checkedValue={categoryStatus} label={"Enable Category"} onChange={(e) => handleChangeChecked(e)} name="status" />
                <small className="block text-gray-500 ml-4 italic font-semibold relative -top-2">Store Front</small>
            </div>
        </div>
    )
}

export default CategoryStatusUpdate