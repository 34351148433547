import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useCreate from '../useCreate'
import toast from 'react-hot-toast'

const useCreateCategory = ({
    reset
}) => {
    const { categoryId } = useParams()
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { setDataToServer } = useCreate({
        url: 'category_create_and_update',
        refreshUrl: 'category_listing',
        onSuccess: () => {
            // handleResetForm()
            if (searchParams.get("mode") === 'edit') {
                navigate(`/catalog/category`)
            }else{
                reset?.()
            }
            toast.success("Category Added Successfully", {
                position: "top-right",
                duration: 3000
            })
        }
    })
    const handleSubmit = (data) => {
        let finalFormData
        if (categoryId) {
            finalFormData = {
                ...data,
                category_id: categoryId
            }
        } else {
            finalFormData = data
        }
        // console.log(finalFormData);
        setDataToServer.mutate(finalFormData)
    }

    return { handleSubmit }
}

export default useCreateCategory