const COUNT_ABBRS = ["", "K", "M", "G", "T", "P", "E", "Z", "Y"];
export const formatCount = (count, withAbbr = false, decimals = 2) => {
  const i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
  let result = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
  if (withAbbr) {
    result += `${COUNT_ABBRS[i]}`;
  }
  return result;
};

export const formatDate = (unixTimeStamp) => {
  const date = new Date(unixTimeStamp * 1000);
  if (!date) return;
  const formatedDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
  return formatedDate;
};

export const generateSlug = (input) => {
  return input
    .trim() // Remove leading and trailing whitespaces
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^a-zA-Z0-9\-]/g, ""); // Remove non-alphanumeric characters except hyphens
};

export const generateURL = (obj) => {
  // Initialize an array to hold key-value pairs
  const keyValuePairs = [];

  // Loop through the object's properties
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Encode the key and value and add them to the array
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(obj[key]);
      keyValuePairs.push(`${encodedKey}=${encodedValue}`);
    }
  }

  // Join the key-value pairs with "&" and add them to the base URL
  const queryString = keyValuePairs.join("&");
  const url = `?${queryString}`;

  return url;
};

export const checkIfEmpty = (value) => {
  return value === null || value === undefined || value === "";
};

export const checkIfNotEmpty = (value) => {
  return (
    value !== null && value !== undefined && value !== "" && value !== "null"
  );
};
