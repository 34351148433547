import ShipmentForm from '../../../components/system/shipment/ShipmentForm'
import useFetchSingleShippingData from '../../../hooks/system/shipments/useFetchSingleShippingData'
import {useParams} from 'react-router-dom'

const EditShipmentData = () => {
  const params = useParams()
  const singleShipmentData = useFetchSingleShippingData({
    id: params?.shipmentId
  })
  return (
    <div>
      <div className="category px-4">
        <div className="category__head headingBorder__b">
          <h2 className="titleHeading">Edit Shipment</h2>
        </div>
      </div>
      <div>
        <ShipmentForm data={singleShipmentData?.data?.data} mode={'edit'}/>
      </div>
    </div>
  )
}

export default EditShipmentData