import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup"
import Spinner from "../../spinner";
import { useEffect } from "react";
import usePostCurrencyList from "../../../hooks/system/currency/usePostCurrencyList";


// id
// title
// code
// symbol_left
// symbol_right
// decimal_place
// value || "conversion rate"


const schema = yup
    .object({
        title: yup.string().required('Title is required.'),
        code: yup.string().required('Code is required.'),
        symbol_left: yup.string(),
        symbol_right: yup.string(),
        decimal_place: yup.string(),
        value: yup.string(),
    })
    .required()

const CurrencyForm = ({
    selectedData,
    onCloseForm
}) => {
    const { register,setValue, handleSubmit,formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            title: "",
            code: "",
            symbol_left: "",
            symbol_right: "",
            decimal_place: "",
            value: "",
        }
    });

    const postCurrency = usePostCurrencyList({
        onSuccess: () => {
            onCloseForm()
        }
    })

    useEffect(() => {
        if(selectedData?.title){
            Object.keys(selectedData).forEach((key) => {
                return setValue(key,selectedData[key])
            })
        }
    },[selectedData])
    return (
        <div>
            <form onSubmit={handleSubmit(postCurrency)}>
                <div className=" grid grid-cols-2 gap-5 p-5">
                    <div>
                        <label htmlFor="Title" className='label'>Title <small className="text-red-700">*</small></label>
                        <input {...register('title')} className='input' placeholder='Title' />
                        <small className=' text-red-600'>{errors.title?.message}</small>
                    </div>
                    <div>
                        <label htmlFor="Code" className='label'>Code <small className="text-red-700">*</small></label>
                        <input {...register('code')} className='input' placeholder='Code' />
                        <small className=' text-red-600'>{errors.code?.message}</small>
                    </div>
                    <div>
                        <label htmlFor="Symbol Left" className='label'>Symbol Left</label>
                        <input {...register('symbol_left')} className='input' placeholder='Symbol Left' />
                    </div>
                    <div>
                        <label htmlFor="Symbol Right" className='label'>Symbol Right</label>
                        <input {...register('symbol_right')} className='input' placeholder='Symbol Right' />
                    </div>
                    <div>
                        <label htmlFor="decimal place" className='label'>Decimal</label>
                        <input {...register('decimal_place')} className='input' placeholder='Decimal' />
                    </div>
                    <div>
                        <label htmlFor="Value" className='label'>Conversion Rate</label>
                        <input {...register('value')} className='input' placeholder='Value' />
                    </div>
                </div>
                <div className='text-center my-6'>
                    {false ? <Spinner /> : <Button type='submit' variant="contained" className='primaryBtn-contained'>
                        Save
                    </Button>}
                </div>
            </form>
        </div>
    )
}

export default CurrencyForm