import useRead from "../useRead";

const useGetCategoryList = ({ limit = 50 } = {}) => {
  const initialData = {
    pageNo: 0,
    limit: limit,
    searchQuery: "",
  };
  const handlePageClick = (val) => {
    let pageNo = {
      ...paramsObject,
      pageNo: val.selected,
    };
    setGetListParams(pageNo);
  };
  const handleSearchVal = (e) => {
    let value = e.target.value;
    let copyPrams = {
      ...paramsObject,
      searchQuery: value,
      pageNo: 0,
    };
    setGetListParams(copyPrams);
  };

  const handleSetLimit = (e) => {
    let value = e.target.value;
    let copyPrams = {
      ...paramsObject,
      limit: value,
    };
    setGetListParams(copyPrams);
  };
  const { data, paramsObject, setGetListParams } = useRead({
    initialData,
    url: "category_listing",
    method: "GET",
  });
  return {
    data,
    handlePageClick,
    handleSearchVal,
    handleSetLimit,
    paramsObject,
  };
};

export default useGetCategoryList;
