import { Controller, useForm } from "react-hook-form";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import Spinner from "../../spinner";
import { Button } from "@mui/material";
import useCreateShipment from "../../../hooks/system/shipments/useCreateShipment";
import { useSearchParams } from 'react-router-dom'
import { useEffect } from "react";
import SelectSearch from 'react-select-search';
import useGetCountryCodeList from "../../../hooks/system/shipments/useGetCountryCodeList";
import 'react-select-search/style.css'
import SelectSearchOptions from "../../elements/select/SelectSearchOptions";


// id
// method : (AIR | SHIP)
// country_id
// country_code
// weight_from
// weight_to
// port
// port_code
// charges
// charges_type


const schema = yup
    .object({
        method: yup.string().required('Method is required.'),
        country_id: yup.string().required('Country is required.'),
        country_code: yup.string().required('Country Code is required.'),
        weight_from: yup.string().required('Weight From is required.'),
        weight_to: yup.string().required('Weight To is required.'),
        charges: yup.string().required('Charges is required.'),
        charges_type: yup.string().required('Charges Type is required.'),
    })
    .required()
const ShipmentForm = ({
    mode,
    data
}) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const methodFromUrl = searchParams.get('method')
    const { register, setValue, getValues, control, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            method: methodFromUrl,
            country_id: '',
            country_name: '',
            weight_from: '',
            weight_to: '',
            port: '',
            port_code: '',
            charges: '',
            charges_type: '',
        }
    });
    const { handlePutToServer, setDataToServer } = useCreateShipment()
    const { countryCodeList,handleSearch } = useGetCountryCodeList()
    const handleSelectOption = (val) => {
        setValue('country_id',val.value)
    }
    const handleChangeCountry = (val) => {
        handleSearch(val)
    }

    useEffect(() => {
        if(data){
            Object.keys(data).forEach((key) => {
                return setValue(key,data[key])
            })
            setValue('country_id',data['country_id'])
            setValue('country_name',data['country_name'])
        }
    },[data])

    return (
        <div>
            <form onSubmit={handleSubmit(handlePutToServer)}>
                <div className=" grid grid-cols-2 gap-5 p-5">
                    <div>
                        <label htmlFor="Weight From" className='label'>Weight From <small className="text-red-700">*</small></label>
                        <input {...register('weight_from')} className='input' placeholder='Weight From' />
                        <small className=' text-red-600'>{errors.weight_from?.message}</small>
                    </div>
                    <div>
                        <label htmlFor="Weight To" className='label'>Weight To <small className="text-red-700">*</small></label>
                        <input {...register('weight_to')} className='input' placeholder='Weight To' />
                        <small className=' text-red-600'>{errors.weight_to?.message}</small>
                    </div>
                    {methodFromUrl === 'SHIP' &&
                        <>
                            <div>
                                <label htmlFor="Port" className='label'>Port <small className="text-red-700">*</small></label>
                                <input {...register('port', { required: 'port is required' })} className='input' placeholder='Port' />
                                <small className=' text-red-600'>{errors.port?.message}</small>
                            </div>
                            <div>
                                <label htmlFor="Port Code" className='label'>Port Code <small className="text-red-700">*</small></label>
                                <input {...register('port_code', { required: 'Port Code is required' })} className='input' placeholder='Port Code' />
                                <small className=' text-red-600'>{errors.port_code?.message}</small>
                            </div>
                        </>
                    }
                    <div>
                        <label htmlFor="Charges" className='label'>Charges <small className="text-red-700">*</small></label>
                        <input {...register('charges')} className='input' placeholder='Charges' />
                        <small className=' text-red-600'>{errors.charges?.message}</small>
                    </div>
                    <div>
                        <label htmlFor="Charge Type" className='label'>Charges Type <small className="text-red-700">*</small></label>
                        <input {...register('charges_type')} className='input' placeholder='Charges Type' />
                        <small className=' text-red-600'>{errors.charges_type?.message}</small>
                    </div>
                    {/* <div>
                        <label htmlFor="Country" className='label'>Country <small className="text-red-700">*</small></label>
                        <Controller
                            name="country_id"
                            control={control}
                            rules={{
                                required: "Country is required",
                            }}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <SelectSearch
                                    search
                                    getOptions={loadOptions}
                                    className={'custom-select-search'}
                                    options={countryCodeList?.data?.data?.map(option => ({ value: option.id, name: option.name })) } 
                                    value={value}
                                    onChange={(val) => {
                                        onChange(val)
                                        handleChangeCountry(val)
                                    }}
                                    name="language"
                                    placeholder="Country" />
                            )}
                        />
                        <small className=' text-red-600'>{errors.country_id?.message}</small>
                    </div> */}
                    <div>
                        <label htmlFor="Country" className='label'>Country <small className="text-red-700">*</small></label>
                        <SelectSearchOptions
                            options={countryCodeList?.data?.data?.map(option => ({ value: option.id, name: option.name }))}
                            onChange={handleChangeCountry}
                            textValue={getValues('country_name')}
                            value={getValues('country_id')}
                            placeholder="Country"
                            onSelectOption={handleSelectOption}
                        />
                    </div>
                </div>
                <div className='text-center my-6'>
                    {setDataToServer.isLoading ? <Spinner /> : <Button type='submit' variant="contained" className='primaryBtn-contained'>
                        Save
                    </Button>}
                </div>
            </form>
        </div>
    )
}

export default ShipmentForm