import Pagination from "../../../components/elements/pagination"
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { RiDeleteBinLine } from "react-icons/ri";
import EditCurrency from "./EditCurrency";
import { useState } from "react";
import DeleteCurrency from "./DeleteCurrency";

const CurrencyTable = ({
    data,
    onPageNumberClick,
    reqObj
}) => {
    const [editDialog, setEditDialog] = useState(false)
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [selectedData, setSelectedData] = useState({})
    const [selectedId,setSelectedId] = useState(null)
    const handleClickEdit = (data) => {
        setSelectedData(data)
        setEditDialog(true)
    }
    const handleDelete = (id) => {
        setSelectedId(id)
        setDeleteDialog(true)
    }
    return (
        <>
            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                    <thead>
                        <tr className="text-left">
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-left">
                                Id
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Title
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                Code
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                Symbol
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                Conversion Value
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                Decimal Place
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.data?.data?.map((currencyData, idx) => {
                            const { id, title, code, decimal_place, symbol_left, symbol_right, value } = currencyData || {};
                            return (
                                <tr key={idx}>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                        <div className=" p-2 border-r text-xs">
                                            {id}
                                        </div>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName border-r">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-xs">
                                            {title}
                                        </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName border-r">
                                        <span className="text-gray-700 px-6 py-3 flex justify-center items-center text-xs">
                                            {code}
                                        </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName border-r">
                                        <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-xs">
                                            {symbol_left} {symbol_right}
                                        </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName border-r">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-xs justify-center">
                                            {value}
                                        </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName border-r">
                                        <span className="text-gray-700 px-6 py-3 flex items-center text-xs justify-center">
                                            {decimal_place}
                                        </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 emailAddress">
                                        <div className="flex items-center justify-center">
                                            <IconButton onClick={() => handleClickEdit(currencyData)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(id)}>
                                                <RiDeleteBinLine className="text-lg cursor-pointer text-gray-600" />
                                            </IconButton>
                                            {/* onClick={() => handleDelete(id)} */}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="mt-5">
                <Pagination
                    currentPage={reqObj?.pageNo}
                    lengthofItems={data?.data?.total_count}
                    limit={data?.data?.per_page}
                    onPageChange={onPageNumberClick}
                />
            </div>
            {editDialog && <EditCurrency data={selectedData} open={editDialog} onClose={() => setEditDialog(false)} />}
            <DeleteCurrency selectedId={selectedId} open={deleteDialog} handleClose={() => setDeleteDialog(false)}/>
        </>
    )
}

export default CurrencyTable