import { Dialog, Slide, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, useState } from 'react'
import Spinner from '../../../components/spinner';
import { useQueryClient, useMutation } from "@tanstack/react-query";
import MuiAlert from '@mui/material/Alert';
import { addCoupon } from '../../../apis/coupon';
import useCreate from '../../../hooks/useCreate';
import toast from 'react-hot-toast'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IS_REQUIRED = true

const initialstate = {
    coupon_name: '',
    coupon_code: '',
    is_coupon_percentage: '',
    coupon_discount_amount: '',
    minimum_order_value: '',
    maximum_order_value: '',
    discount_after_max: '',
    is_only_for_first_timer: '',
    coupon_valid_from: '',
    coupon_valid_till: ''
}

function EditCoupons({ open, handleClose, editData }) {
    const queryClient = useQueryClient()
    const [couponFormData, setCouponFormData] = useState({})
    useEffect(() => {
        setCouponFormData({ ...editData })
    }, [editData])
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const closeErrorMessage = () => {
        setIsError(false)
    }
    const handleCouponFormData = (e) => {
        let $this = e.target
        console.log($this.type);
        let value = $this.value
        let data = {
            ...couponFormData,
            [$this.name]: $this.type === 'date' ? value : value
        }
        setCouponFormData(data)
    }

    const handleCloseAndReset = () => {
        setIsSuccess(false)
        setIsError(false)
        setCouponFormData(initialstate)
        handleClose()
    }
    const { setDataToServer } = useCreate({
        url: 'coupon_create_and_update',
        refreshUrl: 'coupon_listing',
        onSuccess: () => {
            setIsSuccess(true)
            setTimeout(() => {
                handleCloseAndReset()
            },2000)
            toast.success("Coupon Edited Successfully",{
                position:"top-right",
                duration:3000
            })
        },
        onError:() => {
            setIsError(true)
            toast.error("Oops! Something Went Wrong",{
                position:"top-right",
                duration:3000
            })
        }
    })
    const handleSubmitCouponForm = (e) => {
        e.preventDefault()
        // console.log(couponFormData);
        setDataToServer.mutate(couponFormData)
    }
    return (
        <Dialog fullScreen fullWidth={true} open={open} onClose={handleClose} TransitionComponent={Transition}>
            <div className='container_xxl px-4'>
                <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                    <div>
                        <IconButton onClick={handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div>
                        <h1 className='heading'>Edit Coupon</h1>
                    </div>
                </div>
                <div className='form-body py-8'>
                    {isError && <Alert onClose={closeErrorMessage} className='mb-4' severity="error" sx={{ width: '100%' }}>{setDataToServer?.error?.message}, Please try again later</Alert>}
                    {isSuccess && <Alert onClose={closeErrorMessage} className='mb-4' severity="success" sx={{ width: '100%' }}>Coupon Updated Succesfully</Alert>}
                    <form className='' autoComplete='off' onSubmit={handleSubmitCouponForm}>
                        <div className='grid grid-cols-2 gap-4'>
                            <div>
                                <label htmlFor="Coupon Name" className='label'>Coupon Name <small className="text-red-700">*</small></label>
                                <input type="text" required={IS_REQUIRED} placeholder='Enter Coupon Name' value={couponFormData.coupon_name} onChange={handleCouponFormData} name='coupon_name' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Coupon Code" className='label'>Coupon Code <small className="text-red-700">*</small></label>
                                <input type="text" required={IS_REQUIRED} placeholder='Enter Coupon Code' value={couponFormData.coupon_code} onChange={handleCouponFormData} name='coupon_code' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Is Coupon Percentage Based" className='label'>Is Coupon Percentage Based <small className="text-red-700">*</small>?</label>
                                <select name="is_coupon_percentage" required={IS_REQUIRED} value={couponFormData.is_coupon_percentage} onChange={handleCouponFormData} className='input'>
                                    <option value="">--Select Coupon Percentage Based--</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="Coupon Discount Amount" className='label'>Coupon Discount Amount <small className="text-red-700">*</small></label>
                                <input type="number" required={IS_REQUIRED} placeholder='Enter Coupon Discount Amount' value={couponFormData.coupon_discount_amount} onChange={handleCouponFormData} name='coupon_discount_amount' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Minimum Order Value" className='label'>Minimum Order Value <small className="text-red-700">*</small></label>
                                <input type="number" required={IS_REQUIRED} placeholder='Enter Minimum Order Value' value={couponFormData.minimum_order_value} onChange={handleCouponFormData} name='minimum_order_value' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Maximum Order Value" className='label'>Maximum Order Value <small className="text-red-700">*</small></label>
                                <input type="number" required={IS_REQUIRED} placeholder='Enter Maximum Order Value' value={couponFormData.maximum_order_value} onChange={handleCouponFormData} name='maximum_order_value' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Flat Discount After Maximum Order Value" className='label'>Flat Discount After Maximum Order Value <small className="text-red-700">*</small></label>
                                <input type="number" required={IS_REQUIRED} placeholder='Enter Flat Discount After Maximum Order Value' value={couponFormData.discount_after_max} onChange={handleCouponFormData} name='discount_after_max' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Valid Only For First Time Users" className='label'>Valid Only For First Time Users <small className="text-red-700">*</small></label>
                                <select required={IS_REQUIRED} name="is_only_for_first_timer" className='input' value={couponFormData.is_only_for_first_timer} onChange={handleCouponFormData}>
                                    <option value="">--Select Valid Only For First Time Users--</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                            <div className='input-container'>
                                <label htmlFor="Coupon Valid From" className='label'>Coupon Valid From <small className="text-red-700">*</small></label>
                                <input required={IS_REQUIRED} type="date" placeholder='Enter Flat Discount After Maximum Order Value' name='coupon_valid_from' value={couponFormData.coupon_valid_from} onChange={handleCouponFormData} className='input' />
                            </div>
                            <div className='input-container'>
                                <label htmlFor="Coupon Valid Till" className='label'>Coupon Valid Till <small className="text-red-700">*</small></label>
                                <input required={IS_REQUIRED} type="date" placeholder='Enter Flat Discount After Maximum Order Value' name='coupon_valid_till' value={couponFormData.coupon_valid_till} onChange={handleCouponFormData} className='input' />
                            </div>
                        </div>
                        <div className='text-center my-6'>
                            {setDataToServer.isLoading ? <Spinner /> :
                                <Button type='submit' variant="contained" className='primaryBtn-contained'>
                                    Save
                                </Button>}
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}

export default EditCoupons