import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../../apis/axios";
import { TOKEN_KEY } from "../../config";

const useUploadMedia = ({
    refreshUrl = '',
    url = '',
    onSuccess,
    onError
}) => {
    const queryClient = useQueryClient()
    const upload = useMutation((data) => _uploadMedia(data), {
        onError: (err) => {
            onError?.(err)
        },
        onSuccess: (data) => {
            {refreshUrl && queryClient.invalidateQueries(refreshUrl)}
            onSuccess?.(data)
        }
    })
    const _uploadMedia = async (getFormData) => {
        let TOKEN = sessionStorage.getItem(TOKEN_KEY);
        let fd = new FormData()
        for (var key in getFormData) {
            if (key === 'thumbnail') {
                for (let i = 0; i < getFormData['thumbnail'].length; i++) {
                    fd.append('thumbnail', getFormData['thumbnail'][i]);
                }
            }else{
                fd.append(key, getFormData[key]);
            }
    
        }
        const response = await axios({
            method: "POST",
            data: fd,
            url: `/${url}`,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
        })
        return response.data
    }
    return { upload }
}

export default useUploadMedia