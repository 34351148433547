import { useEffect, useState } from "react"
import useClickOutside from "../../../hooks/useClickOutside"

const SelectSearchOptions = ({
  options,
  textValue,
  onChange,
  placeholder,
  onSelectOption,
  value
}) => {
  const optionDropdown = useClickOutside(() => {
    setIsFocused(false)
  }) 
  console.log(textValue);
  const [isFocused,setIsFocused] = useState(false)
  const [selectedId,setSelectedId] = useState(null)
  const [searchValue,setSearchValue] = useState(textValue)
  const handleChangeSearch = (e) => {
    const value = e.target.value
    onChange(value)
    setSearchValue(value)
  }
  const onSelect = (opt) => {
    onSelectOption(opt)
    setSearchValue(opt.name)
    setSelectedId(opt.value)
    setIsFocused(false)
  }
  useEffect(() => {
    if(textValue){
      setSearchValue(textValue)
      setSelectedId(value)
    }
  },[textValue,value])
  return (
    <div className={`relative w-full custom-select-search-container ${isFocused ? 'custom-select-search-has-focus' : ''}`}>
        <input placeholder={placeholder} type="text" className="w-full input" value={searchValue} onChange={handleChangeSearch}  onFocus={() => setIsFocused(true)}/>
        <div ref={optionDropdown} className="w-full absolute divide-y bg-white rounded-md z-40 max-h-[20rem] translate-y-2 overflow-auto shadow-md selectOptionDropDown">
          {options?.map((opt,optIdx) => {
            return <button type="button" onClick={() => onSelect(opt)} key={optIdx} className={`block w-full text-left py-2 px-2 hover:bg-gray-100 ${selectedId === opt.value ? ' bg-gray-200' : ''}`}>{opt.name}</button>
          })}
        </div>
    </div>
  )
}

export default SelectSearchOptions