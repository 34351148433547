import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../../apis/axios";
import { TOKEN_KEY } from "../../config";



function useDownloadPdf() {
  const queryClient = useQueryClient()
  const setDataToServer = useMutation((data) => setUsers(data), {
    onError: (err) => {
      // onError?.(err)
    },
    onSuccess: (response) => {
        const blob = new Blob([response], { type: response.headers['content-type'] });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'order_report.pdf'); // or whatever file name you want
            document.body.appendChild(link);
            link.click();
            link.remove();

            window.URL.revokeObjectURL(downloadUrl);
      // onSuccess?.(data)
    }
  })
  const handleDownloadPdf =(data)=>{
    setDataToServer.mutate(data)
  }
  const setUsers = async (getFormData) => {
    let TOKEN = sessionStorage.getItem(TOKEN_KEY);
    let fd = new FormData()
    for (var key in getFormData) {
      if (Array.isArray(getFormData[key]) || typeof getFormData[key] === 'object') {
        fd.append(key, JSON.stringify(getFormData[key]));
      } else {
        fd.append(key, getFormData[key]);
      }
    }
    const response = await axios({
      method: "POST",
      data: fd,
      url: `/download_invoice`,
      headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
    })
    return response.data
  }
  return { setDataToServer,handleDownloadPdf }

}

export default useDownloadPdf